import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/templates-invoices`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/templates-invoices/${id}?include=organization`, options)
    .then((response) => {
      let templateInvoice = jsona.deserialize(response.data);
      delete templateInvoice.links;
      return templateInvoice;
    });
}

function add(templateInvoice) {
  const payload = jsona.serialize({
    stuff: templateInvoice,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  return axios.post(`${url}/templates-invoices`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(templateInvoice) {
  const payload = jsona.serialize({
    stuff: templateInvoice,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/templates-invoices/${templateInvoice.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/templates-invoices/${id}`, options);
}
function addLogo(payload) {

  const options = {
    headers: {
      Accept:  [
          'application/vnd.api+json',
          'multipart/form-data',
      ],
      "Content-Type" : [
          'application/vnd.api+json',
          'multipart/form-data',
      ],
    },
  };

  return axios.post(`${url}/templates-invoices/add-logo`, payload, options).then((response) => {
    return jsona.deserialize(response);
  });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  addLogo,
};
