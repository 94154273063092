import StatisticsModuleLayout from "@/views/Pages/Reports/ReportsModule/StatisticsModuleLayout.vue";
import StatisticsListTable from "@/views/Pages/Reports/ReportsModule/partials/StatisticsListTable.vue";
import PaymentStatisticsListTable from "@/views/Pages/Reports/ReportsModule/partials/PaymentStatisticsListTable.vue";
import authenticated from "@/middleware/authenticated";

const reportsModuleRoutes = [
  {
    path: "statistics",
    component: StatisticsModuleLayout, // Parent layout
    children: [
      {
        path: "spots",
        name: "Spots Statistics",
        component: StatisticsListTable, // Load spots table
        meta: {
          middleware: authenticated,
          hasLevel2: false,
        },
      },
      {
        path: "payments",
        name: "Payment Statistics",
        component: PaymentStatisticsListTable, // Load payments table
        meta: {
          middleware: authenticated,
          hasLevel2: false,
        },
      },
    ],
  },
];

export default reportsModuleRoutes;
