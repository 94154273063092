export default {
  TEMPLATES_MAILS_LIST: "Liste des modèles courriels",
  ADD_TEMPLATE_MAIL: "Ajouter un modèle courriel",
  TEMPLATE_MAIL_ADDED: "Modèle courriel ajouté",
  EDIT_TEMPLATE_MAIL: "Modifier un modèle courriel",
  TEMPLATE_MAIL_UPDATED: "Modèle courriel mise à jour",
  DELETE_THIS_TEMPLATE_MAIL: "Supprimer ce modèle courriel ?",
  TEMPLATE_MAIL_DELETED: "Modèle courriel supprimée",
  TEMPLATE_DETAIL: "Détails du modèle",
  SUBJECT: "Objet",
  TITLE: "Titre",
  FROM: "De",
  TO: "À",
  CC: "Cc",
  BCC: "Cci",
  TYPE: "Type",
  CONTENT: "Contenu",
  VIEW_TEMPLATE_MAIL: "Modèle courriel",
  COMMUNICATION: "Communication",
  LOGO: "Logo",
  BACKGROUND_COLOR: "Couleur du fond",
  SECTION: "Section",
  FONT_SIZE: "Taille du texte",
  FONT_FAMILLY: "Polices",
  TITLE_COLOR: "Couleur du titre",
  TEXT_COLOR: "Couleur du texte",
  LINKS_COLOR: "Couleur des liens",
  BUTTONS_COLOR: "Couleurs des boutons",
  PERSONNALISATION: "Personnalisation",
  MAIL_CONTENT: "Contenu mail",
  IMPORT_LOGO: "Importer un logo",
  ADD_PICTURE: "Ajouter une photo",
  IS_DEFAULT_TEMPLATE: "Modèle par défault",
};
