<template>
  <div class="stats-wrapper-cards">
    <div
      class="stats-wrapper-cards-item"
      v-for="(value, key) in statistics"
      :key="key"
      v-if="statisticsTitles[key]"
    >
      <div class="item-wrapper">
        <div class="item-wrapper-icon">
          <img src="/img/kw-graph.svg" alt="icon" />
        </div>
        <div class="item-wrapper-text">
          <ul>
            <li>{{ statisticsTitles[key] }}</li>
            <li
              v-if="
                key === 'average_spots_reserved' ||
                key === 'reserved_percentage'
              "
            >
              {{ value + " %" }}
            </li>
            <li
              v-if="
                key === 'total_revenue' ||
                key === 'average_price_per_night' ||
                key === 'revenue_per_available_room'||
                key === 'adr'
              "
            >
              {{ value + " $" }}
            </li>
            <li v-if="
              key === 'spots_reserved' || 
              key === 'total_bookings' ||
              key === 'total_spots' ||
              key === 'total_nights_booked'
            ">
              {{ value }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";

export default {
  props: {
    statistics: Object,
  },
  components: {
    StatsCard,
  },
  data() {
    return {
      statisticsTitles: {
        total_spots: this.$t("REPORTS.AVAILABLE_SPOTS"),
        //total_bookings: this.$t("REPORTS.AVAILABLE_SPOTS"),
        //spots_reserved: this.$t("REPORTS.TOTAL_OCC_SPOTS"),
        //nights_reserved: "Total nuits réservées",
        reserved_percentage: this.$t("REPORTS.RESERVATION_RATE"),
        adr: this.$t("REPORTS.ADR"),
        total_revenue: this.$t("REPORTS.TOTAL_REVENUE"),
        total_nights_booked: this.$t("REPORTS.NIGHTS"),
        //average_spots_reserved: this.$t("REPORTS.OVERALL_OCC_RATE"),
      },
    };
  },
};
</script>
