<template>
  <div>
    <div class="page-wrapper-table stats">
      <div class="stats-wrapper">
        <div class="stats-wrapper-title">
          <h4>{{ $t("REPORTS.PAYMENT_STATISTICS") }}</h4>
        </div>
        <div class="stats-wrapper-filters">
          <div class="stats-wrapper-filters-left">
            
          </div>
          <!-- <div class="stats-wrapper-filters-right">
            <el-radio-group v-model="downloadRadio">
              <el-radio :label="3">
                <img src="/img/kw-excel.svg" alt="icon" />
                <span>Excel</span>
              </el-radio>
              <el-radio :label="6">
                <img src="/img/kw-csv.svg" alt="icon" />
                <span>CSV</span>
              </el-radio>
              <el-radio :label="9">
                <img src="/img/kw-pdf.svg" alt="icon" />
                <span>PDF</span>
              </el-radio>
            </el-radio-group>

            <Button @click="printFunctionCall">
              <img src="/img/kw-printer.svg" alt="icon" />
              <span>{{ $t("COMMON.EXPORT") }}</span>
            </Button>
          </div> -->
        </div>
      </div>

      <WidgetsPayment :statistics="this.statistics"> </WidgetsPayment>

      
      <h4 class="title">{{ $t("REPORTS.PAYMENTS_LIST") }}</h4>

      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          
          
          <spot-month-selector
            :placeholder="$t('COMMON.MONTH')"
            @monthChanged="(months) => (selectedMonths = months)"
          />

          <customer-selector
            :customer="customerModel?.id"
            :filterable="true"
            :multiple="true"
            :showAll="false"
            @customerChanged="
              (customer) => (selectedCustomers = customer)
            "
          ></customer-selector>

          <payment-card-selector
            :placeholder="$t(`SPOTS.SPOT_TYPE`)"
            :showAll="false"
            :multiple="true"
            @paymentCardChange="(cards) => (selectedCards = cards)"
          />

          <!-- <spot-category-selector
            :showAll="false"
            :multiple="true"
            @spotCategoryChanged="
              (spotCategories) => (selectedCategories = spotCategories)
            "
          />
          <spot-type-selector
            :placeholder="$t(`SPOTS.SPOT_TYPE`)"
            :showAll="false"
            :multiple="true"
            @spotTypeChanged="(spotTypes) => (selectedSpotTypes = spotTypes)"
          /> -->

        </div>

        <!-- <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>

      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <!--show-summary-->
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="tableDataWithTotal"
            :default-sort="{ prop: 'code', order: 'ascending' }"
            :loading="loading"
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column :label="$t('REPORTS.USER')" prop="user" min-width="140" sortable>
              <template v-slot="{ row }">
                <span v-if="row.isTotal" class="font-bold" style="font-weight: 600;">{{ $t("COMMON.TOTAL") }}</span>
                <span v-else>{{ row.sales_invoice.customer.firstname }} {{ row.sales_invoice.customer.lastname }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('REPORTS.FOLIO')" prop="folio" min-width="120" sortable>
              <template v-slot="{ row }">
                <a 
                  v-if="row.sales_invoice && row.sales_invoice.code_folio"
                  :href="'/sales/invoices/?id=' + getFolioId(row.sales_invoice.code_folio) + '&action=view&tab=folio'" target="_blank"
                  :style="{ fontWeight: row.isTotal ? '600' : '400', color: '#374151' }"
                >{{ row.sales_invoice?.code_folio }}</a>
              </template>
            </el-table-column>
            <el-table-column 
              :label="$t('REPORTS.BOOKING')" 
              prop="booking_code" min-width="120" sortable
            >
              <template v-slot="{ row }">
                <div v-if="row.sales_invoice && row.sales_invoice.items">
                  <a 
                    v-for="(item, index) in row.sales_invoice.items" 
                    :key="index" 
                    v-if="item.booking_code" 
                    :href="'/bookings/bookings/?id=' + item.booking_id + '&action=view'" target="_blank"
                    :style="{ 
                      fontWeight: row.isTotal ? '600' : '400', 
                      display: 'block', color: '#374151', 
                      marginTop: (row.sales_invoice.items.length > 1 && index>0) ?'20px' : '0px' 
                    }"
                  >
                    {{ item.booking_code }}
                  </a>
                </div>
              </template>
            </el-table-column> 
            <el-table-column :label="$t('REPORTS.ARRIVING_DATE')" prop="booking_code" min-width="140" sortable>
              <template v-slot="{ row }">
                <div v-if="row.sales_invoice && row.sales_invoice.items">
                  <span 
                    v-for="(item, index) in row.sales_invoice.items" 
                    :key="index" 
                    v-if="item.booking_date" 
                    :style="{ 
                      fontWeight: row.isTotal ? '600' : '400', 
                      display: 'block', color: '#374151', 
                      marginTop: (row.sales_invoice.items.length > 1 && index>0) ?'20px' : '0px' 
                    }"
                  >
                    {{ convertDay(item.booking_date) }}
                </span>
                </div>
              </template>
            </el-table-column>  
            <el-table-column :label="$t('REPORTS.PAYMENTS')" prop="amount" min-width="120" sortable>
              <template v-slot="{ row }">
                <!-- <div v-if="row.sales_invoice && row.sales_invoice.items && row.sales_invoice.items.length>1">
                  <span 
                    v-for="(item, index) in row.sales_invoice.items" 
                    :key="index" 
                    v-if="item.booking_date" 
                    :style="{ 
                      fontWeight: row.isTotal ? '600' : '400', 
                      display: 'block', color: '#374151', 
                      marginTop: (row.sales_invoice.items.length > 1 && index>0) ?'20px' : '0px' 
                    }"
                  >
                    {{ Math.round((item.quantity*item.unit_price*100),2)/100 }} $
                  </span>
                </div> -->
                <div>
                  <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ row.amount }} $</span>
                </div>
              </template>
            </el-table-column> 
            <el-table-column :label="$t('REPORTS.PAYMENT_MODE')" prop="payment_mode" min-width="140" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ renderCard(row) }}</span>
              </template>
            </el-table-column> 
            <el-table-column :label="$t('REPORTS.DATE_HOUR')" prop="date" min-width="120" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ convertDate(row.date) }}</span>
              </template>
            </el-table-column> 
            
          </el-table>

        </div>
      </div>

      <!-- <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DatePicker,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
  RadioGroup,
  Radio,
} from "element-ui";

// import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SpotTypeSelector from "@/components/SpotTypeSelector";
import PaymentCardSelector from "@/components/PaymentCardSelector";
import SpotMonthSelector from "@/components/SpotMonthSelector";
import SpotCategorySelector from "@/components/SpotCategorySelector";
import EquipmentSelector from "@/components/EquipmentSelector";
import defaultCustomer from "@/views/Pages/CrmModule/CustomerManagement/defaultCustomer";
import { cloneDeep } from "lodash";
import moment from "moment";
import "jspdf-autotable";
import CustomPieChart from "../components/CustomPieChart/CustomPieChart.js";
import CustomLineChart from "../components/CustomLineChart/CustomLineChart";
import CustomPieChartLegendVue from "../components/CustomPieChart/CustomPieChartLegend.vue";
import WidgetsPayment from "../components/WidgetsPayment.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import { CUSTOMER_FORM_TYPE_BOOKING } from "@/constants/customers";

export default {
  name: "payment-statistics-list-table",

  components: {
    CustomerSelector,
    PaymentCardSelector,
    BasePagination,
    // flatPicker,
    OrganizationSelector,
    LocationsSelector,
    EquipmentSelector,
    SpotCategorySelector,
    SpotTypeSelector,
    SpotMonthSelector,
    CustomPieChart,
    CustomLineChart,
    CustomPieChartLegendVue,
    WidgetsPayment,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    customerData: {
      type: Object,
      required: false,
    },
  },

  created() {
    this.optionsTabs = [
      { label: "Tous", value: "all" },
      { label: "Arrivées", value: "arrived" },
      { label: "Départs", value: "departs" },
      { label: "Résidents", value: "residents" },
    ];
    this.selectedOptionTabId = this.listName;
  },

  data() {
    let customerModel = this.customerData
      ? cloneDeep(this.customerData)
      : cloneDeep(defaultCustomer);
    customerModel.form_type = CUSTOMER_FORM_TYPE_BOOKING;
    return {
      list: [],
      completeList: [],
      statistics: {},
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100, 500],
      },
      customerModel,
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedCategories: null,
      selectedCustomers: null,
      selectedSpotTypes: null,
      selectedMonths: null,
      selectedCards: null,
      selectedDateRange: [
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
      ],
      periodDateRange: [
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
      ],
      selectedYear: null,
      selectedMonth: null,
      downloadRadio: 3,
      years: [
        { id: 1, label: "2024", value: "2024" },
        { id: 2, label: "2025", value: "2025" },
      ],
      months: [
        { id: 1, label: "Janvier", value: "Janvier" },
        { id: 2, label: "Février", value: "Février" },
        { id: 3, label: "Mars", value: "Mars" },
        { id: 4, label: "Avril", value: "Avril" },
        { id: 5, label: "Mai", value: "Mai" },
        { id: 6, label: "Juin", value: "Juin" },
        { id: 7, label: "Juillet", value: "Juillet" },
        { id: 8, label: "Aout", value: "Aout" },
        { id: 9, label: "Septembre", value: "Septembre" },
        { id: 10, label: "Octobre", value: "Octobre" },
        { id: 11, label: "Novembre", value: "Novembre" },
        { id: 12, label: "Décembre", value: "Décembre" },
      ]
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    tableDataWithTotal() {
      if (!this.list.length) return [];

      const totalRow = {
        isTotal: true, // Used to format the row differently
        month: this.$t("COMMON.TOTAL"),
        available_spots: this.statistics.total_spots,
        //total_bookings: this.list.reduce((sum, item) => sum + (item.total_bookings || 0), 0),
        spots_reserved: this.list.reduce((sum, item) => sum + (item.spots_reserved || 0), 0),
        reserved_percentage: this.statistics.reserved_percentage, // Custom function
        amount: Math.round((this.list.reduce((sum, item) => sum + (item.amount || 0), 0)) * 100) / 100,
        adr: this.calculateADR(), // Custom function
        nights_booked: this.list.reduce((sum, item) => sum + (item.nights_booked || 0), 0),
      };

      return [...this.list, totalRow]; // Append the total row
    }
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedDateRange: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCategories: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotTypes: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedMonths: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCards: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCustomers: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
  },

  mounted() {},

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getFolioId (code) {
      return code.split("-").pop();
    },
    capitalize(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },

    getStrMonth (dateStr) {
      const date = new Date(dateStr + "-01");
      return date.toLocaleString("en-US", { month: "long", year: "numeric" });
    },

    translateMonth(label) {
      let month = label.slice(0, -5).trim().toUpperCase();
      let year = label.slice(-4);
      return `${this.$t(`REPORTS.${month}`)} ${year}`;
    },

    convertDate(dateStr) {
      if(dateStr) {
      const date = new Date(dateStr.replace(" ", "T"));

      const formatter = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });

      const parts = formatter.formatToParts(date);
      const formattedDate = parts.map(part => {
        if (part.type === 'month') {
          return part.value.charAt(0).toUpperCase() + part.value.slice(1);
        }
        return part.value;
      }).join('');

      const formattedTime = date.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      });

      return `${formattedDate} ${formattedTime}`;
      } else {
        return '';
      }
    },

    convertDay(dateStr) {
      if(dateStr) {
      const date = new Date(dateStr.replace(" ", "T"));

      const formatter = new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });

      const parts = formatter.formatToParts(date);
      const formattedDate = parts.map(part => {
        if (part.type === 'month') {
          return part.value.charAt(0).toUpperCase() + part.value.slice(1);
        }
        return part.value;
      }).join('');

      return formattedDate;
      } else {
        return '';
      }
    },

    renderCard(row) {
      let card = this.capitalize(row.source);
      if(row.source == 'CLOVER') {
        if(row.transaction_data && row.transaction_data!=[]) {
          if (row.transaction_data?.source) {
            card += ' : '+ row.transaction_data?.source.brand;
          } else {
            card += ' : '+ row.transaction_data?.brand??'';
          }
        }
      }
      return card;
    },

    renderName(row) {
      let name = '';
      let firstname = row.sales_invoice.billing_firstname;
      let lastname = row.sales_invoice.billing_lastname;
      let email = row.sales_invoice.billing_email;
      if(firstname && firstname!=="N/A" ) {
        name+=firstname + ' ';
      }
      if(lastname && lastname!=="N/A") {
        name+=lastname;
      }
      if((!firstname || firstname=="N/A") && (!lastname || lastname=="N/A")) {
        if(email && email!=="N/A") {
          name = email;
        }
      }
      return name;
    }, 

    calculateADR() {
      const totalRevenue = this.list.reduce((sum, item) => sum + (item.revenue || 0), 0);
      const totalNights = this.list.reduce((sum, item) => sum + (item.nights_booked || 0), 0);
      return totalNights ? (totalRevenue / totalNights).toFixed(2) : 0;
    },
    calculateAveragePercentage() {
      return this.statistics.reserved_percentage;
      // const totalPercentage = this.list.reduce((sum, item) => sum + (item.reserved_percentage || 0), 0);
      // return (totalPercentage / this.list.length).toFixed(2);
    },

    async getList() {
      try {
        this.loading = true;
        let params = {
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,category",
        };
        // if (this.selectedDateRange) {
        //   params = {
        //     ...params,
        //     filter: {
        //       ...params.filter,
        //       dates_between: this.selectedDateRange.map((date) =>
        //         moment(date).toISOString()
        //       ),
        //     },
        //   };
        // }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedCategories) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_categories: this.selectedCategories,
            },
          };
        }
        if (this.selectedCustomers) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_customers: this.selectedCustomers,
            },
          };
        }
        if (this.selectedSpotTypes) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_types: this.selectedSpotTypes,
            },
          };
        }
        if (this.selectedCards) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_cards: this.selectedCards,
            },
          };
        }
        if (this.selectedMonths?.length) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_months: this.selectedMonths,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        await this.$store.dispatch("reportPayments/list", params);

        this.list = this.$store.getters["reportPayments/list"];
        this.statistics = this.$store.getters["reportPayments/statistics"];
        
        // this.total = this.$store.getters["reportStatistics/listTotal"];
        // this.statistics = this.$store.getters["reportPayments/statistics"];
        
        // this.completeList =
        //   this.$store.getters["reportStatistics/completeList"];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("error ", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      this.sort = order === "ascending" ? prop : `-${prop}`;
      this.getList();
    },

    formatNumber(value, decimals = 2, symbol = "", locale = "en-US") {
      // Vérifie si la valeur est un nombre valide
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return "0";
      }

      // Formatage avec séparateur de milliers et décimales
      const formattedValue = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(numberValue);
      // Retourne la valeur formatée avec le symbole (si fourni)
      return symbol ? `${formattedValue} ${symbol}` : formattedValue;
    },

    
    printFunctionCall() {
      console.log(this.years);
      if (this.downloadRadio === 3) {
        this.exportToExcel();
      } else if (this.downloadRadio === 6) {
        this.exportToCSV();
      } else {
        this.exportToPDF();
      }
    },
  },
};
</script>
