<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>
              {{ $t("REPORTS.STATISCTICS_LIST") }}
            </h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>
                  {{ $t("REPORTS.STATISCTICS_LIST") }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div v-if="globalOrganization?.id">
        <router-view /> <!-- Dynamic component based on route -->
      </div>
      <select-an-organization-message v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";

export default {
  layout: "DashboardLayout",
  components: {
    SelectAnOrganizationMessage,
  },

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },
};
</script>
