import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
 

  return axios.get(`${url}/reports/payments`, options).then((response) => {
    return {
      list: response.data.list,
      //total: response.data.total,
      statistics: response.data.statistics,      
      //meta: response.data.meta,
    };
  });
}



export default {
  list,
};
