import service from "@/store/services/bookings-service";

export const state = () => ({
  list: {},
  booking: {},
  meta: {},
  url: null,
  pricing: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, booking) => {
    state.booking = booking;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_PRICING: (state, pricing) => {
    state.pricing = pricing;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((booking) => {
      commit("SET_RESOURCE", booking);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((booking) => {
      commit("SET_RESOURCE", booking);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((booking) => {
      commit("SET_RESOURCE", booking);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  checkIn({ commit, dispatch }, params) {
    return service.checkIn(params, this.$axios).then((booking) => {
      commit("SET_RESOURCE", booking);
    });
  },

  checkOut({ commit, dispatch }, params) {
    return service.checkOut(params, this.$axios).then((booking) => {
      commit("SET_RESOURCE", booking);
    });
  },

  cancel({ commit, dispatch }, bookingId) {
    return service.cancel(bookingId, this.$axios).then((booking) => {
      commit("SET_RESOURCE", booking);
    });
  },

  cancelInformations({ commit, dispatch }, bookingId) {
    return service.cancelInformations(bookingId, this.$axios).then((data) => {
      return data;
    });
  },

  getPricing({ commit, dispatch }, params) {
    return service.getPricing(params, this.$axios).then((pricing) => {
      commit("SET_PRICING", pricing);
      return pricing;
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  booking: (state) => state.booking,
  url: (state) => state.url,
  pricing: (state) => state.pricing,
  dropdown: (state) => {
    return state.list.map((booking) => ({
      id: booking.id,
      name: booking.code,
    }));
  },
};

const bookings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default bookings;
