var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stats-wrapper-cards"},_vm._l((_vm.statistics),function(value,key){return (_vm.statisticsTitles[key])?_c('div',{key:key,staticClass:"stats-wrapper-cards-item"},[_c('div',{staticClass:"item-wrapper"},[_vm._m(0,true),_c('div',{staticClass:"item-wrapper-text"},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.statisticsTitles[key]))]),(
              key === 'average_spots_reserved' ||
              key === 'reserved_percentage' ||
              key === 'perc_payment'
            )?_c('li',[_vm._v(" "+_vm._s(value + " %")+" ")]):_vm._e(),(
              key === 'total_amount' ||
              key === 'total_remaining' ||
              key === 'avg_amount'
            )?_c('li',[_vm._v(" "+_vm._s(value + " $")+" ")]):_vm._e(),(
            key === 'spots_reserved'
          )?_c('li',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()])])])]):_vm._e()}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-wrapper-icon"},[_c('img',{attrs:{"src":"/img/kw-graph.svg","alt":"icon"}})])
}]

export { render, staticRenderFns }