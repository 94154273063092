export const PoliciesTypes = {
  CANCELLATION: "CANCELLATION",
  GUARANTEE: "GUARANTEE",
  PAYMENTS: "PAYMENTS",
  COMMUNICATION: "COMMUNICATION",
};

export const PaymentPolicyFrequency = {
    AFTER_CREATE : "AFTER_CREATE",
    AFTER_CANCEL : "AFTER_CANCEL",
    AFTER_ARRIVE : "AFTER_ARRIVE",
    BEFORE_ARRIVE : "BEFORE_ARRIVE",
    BEFORE_DEPARTURE : "BEFORE_DEPARTURE",
    AFTER_DEPARTURE : "AFTER_DEPARTURE",
};

export const PaymentTypes = {
  PERCENT: "PERCENT",
  NIGHT: "NIGHT",
  DOLLAR: "DOLLAR",
};

export const PaymentFrequenciesList = [
  { code: PaymentPolicyFrequency.AFTER_CREATE, name: "Après la création de la réservation" },
  { code: PaymentPolicyFrequency.AFTER_CANCEL, name: "Après l'annulation de la réservation" },
  { code: PaymentPolicyFrequency.BEFORE_ARRIVE, name: "Avant et jusqu'à l'arrivée du campeur" },
  { code: PaymentPolicyFrequency.AFTER_ARRIVE, name: "Après l'arrivée du campeur" },
  { code: PaymentPolicyFrequency.BEFORE_DEPARTURE, name: "Avant le départ du campeur" },
  { code: PaymentPolicyFrequency.AFTER_DEPARTURE, name: "Après le départ du campeur" },
];

export const PaymentsTypesList = [
  { code: PaymentTypes.PERCENT, label: "Pourcentage", abbr: "%", desc: "du montant total, taxes comprises" },
  { code: PaymentTypes.NIGHT, label: "Par nuit", abbr: "$/nuit", desc: " taxes comprises" },
  { code: PaymentTypes.DOLLAR, label: "Dollar", abbr: "$", desc: " taxes comprises" },
];

export const TabPoliciesMenu = [
  {
    code: "booking",
    label: "Politiques de réservation",
    route: "policies-booking",
    active: true,
  },
  {
    code: "cancellation",
    label: "Politiques d'annulation",
    route: "policies-cancellation",
    active: true,
  },
  {
    code: "payment",
    label: "Politiques de paiement",
    route: "policies-booking",
    active: true,
  },
  {
    code: "communication",
    label: "Politiques de communication",
    route: "policies-communication",
    active: true,
  },
];