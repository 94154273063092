<template>
  <div class="stats-wrapper-cards">
    <div
      class="stats-wrapper-cards-item"
      v-for="(value, key) in statistics"
      :key="key"
      v-if="statisticsTitles[key]"
    >
      <div class="item-wrapper">
        <div class="item-wrapper-icon">
          <img src="/img/kw-graph.svg" alt="icon" />
        </div>
        <div class="item-wrapper-text">
          <ul>
            <li>{{ statisticsTitles[key] }}</li>
            <li
              v-if="
                key === 'average_spots_reserved' ||
                key === 'reserved_percentage' ||
                key === 'perc_payment'
              "
            >
              {{ value + " %" }}
            </li>
            <li
              v-if="
                key === 'total_amount' ||
                key === 'total_remaining' ||
                key === 'avg_amount'
              "
            >
              {{ value + " $" }}
            </li>
            <li v-if="
              key === 'spots_reserved'
            ">
              {{ value }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "@/components/Cards/StatsCard";

export default {
  props: {
    statistics: Object,
  },
  components: {
    StatsCard,
  },
  data() {
    return {
      statisticsTitles: {
        total_amount: this.$t("REPORTS.TOTAL_REC_PAYMENTS"),
        total_remaining: this.$t("REPORTS.TOTAL_REMAINING_PAYMENTS"),
        perc_payment: this.$t("REPORTS.PERC_GLOBAL_PAYMENT"),
        avg_amount: this.$t("REPORTS.AVG_AMOUNT_PER_TRANS")
      },
    };
  },
};
</script>
