import Vue from "vue";
import Vuex from "vuex";

import appConfig from "./modules/app-config-module";
import auth from "./modules/auth";
import files from "./modules/files-module";
import folders from "./modules/folders-module";
// import alerts from "./modules/alerts-module";
import reset from "./modules/reset";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import permissions from "./modules/permissions-module";
import organizations from "./modules/organizations-module";
import resellers from "./modules/resellers-module";
import resellerProducts from "./modules/reseller-products-module";
import resellerServices from "./modules/reseller-services-module";
import resellerInvoices from "./modules/reseller-invoices-module";
import resellerInvoiceItems from "./modules/reseller-invoice-items-module";
import resellerPayments from "./modules/reseller-payments-module";
import packages from "./modules/packages-module";
import customers from "./modules/customers-module";
import logs from "./modules/logs-module";
import subscriptions from "./modules/subscriptions-module";
import payments from "./modules/payments-module";
import contacts from "./modules/contacts-module";
import suppliers from "./modules/suppliers-module";
import tags from "./modules/tags-module";
import locations from "./modules/locations-module";
import notifications from "./modules/notifications-module";
import notificationSubscriptions from "./modules/notification-subscriptions-module";
import products from "./modules/products-module";
import warehouses from "./modules/warehouses-module";
import warehouseProducts from "./modules/warehouse-products-module";
import purchasesOrders from "./modules/purchases-orders-module";
import purchasesOrderItems from "./modules/purchases-order-items-module";
import purchasesInvoices from "./modules/purchases-invoices-module";
import purchasesInvoiceItems from "./modules/purchases-invoice-items-module";
import purchasesDeliveries from "./modules/purchases-deliveries-module";
import purchasesDeliveryItems from "./modules/purchases-delivery-items-module";
import purchasesPayments from "./modules/purchases-payments-module";
import reportStatistics from "./modules/report-statistics-module";
import reportPayments from "./modules/report-payments-module";
import salesOrders from "./modules/sales-orders-module";
import salesOrderItems from "./modules/sales-order-items-module";
import salesInvoices from "./modules/sales-invoices-module";
import salesInvoiceItems from "./modules/sales-invoice-items-module";
import salesDeliveries from "./modules/sales-deliveries-module";
import salesDeliveryItems from "./modules/sales-delivery-items-module";
import salesPayments from "./modules/sales-payments-module";
import supplierProducts from "./modules/supplier-products-module";
import stockMovements from "./modules/stock-movements-module";
import stockMovementItems from "./modules/stock-movement-items-module";
import imports from "@/store/modules/import-module";
import properties from "@/store/modules/properties-module";
import auctions from "@/store/modules/auctions-module";
import auctionFees from "@/store/modules/auction-fees-module";
import bidSteps from "@/store/modules/bid-steps-module";
import bids from "@/store/modules/bids-module";
import spots from "./modules/spots-module";
import policies from "./modules/policies-module";
import spotCategories from "./modules/spot-categories-module";
import equipmentCategories from "./modules/equipment-categories-module";
import equipments from "./modules/equipments-module";
import spotTypes from "./modules/spot-types-module";
import options from "./modules/options-module";
import organizationOptions from "./modules/organization-options-module";
import bookings from "./modules/bookings-module";
import pricingRules from "./modules/pricing-rules-module";
import pricingSeasons from "./modules/pricing-seasons-module";
import creditCards from "./modules/credit-cards-module";
import templatesMails from "@/store/modules/templates-mails-module";
import typesMails from "@/store/modules/types-mails-module";
import templatesInvoices from "@/store/modules/templates-invoices-module";
import elixioServicesModule from "@/store/modules/elixio-services-module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appConfig,
    auth,
    files,
    folders,
    // alerts,
    reset,
    profile,
    users,
    roles,
    permissions,
    organizations,
    resellers,
    resellerServices,
    resellerProducts,
    resellerInvoices,
    resellerInvoiceItems,
    resellerPayments,
    customers,
    logs,
    packages,
    subscriptions,
    payments,
    contacts,
    suppliers,
    tags,
    locations,
    notifications,
    notificationSubscriptions,
    products,
    warehouses,
    warehouseProducts,
    purchasesOrders,
    purchasesOrderItems,
    purchasesInvoices,
    purchasesInvoiceItems,
    purchasesDeliveries,
    purchasesDeliveryItems,
    purchasesPayments,
    salesOrders,
    reportStatistics,
    reportPayments,
    salesOrderItems,
    salesInvoices,
    salesInvoiceItems,
    salesDeliveries,
    salesDeliveryItems,
    salesPayments,
    supplierProducts,
    stockMovements,
    stockMovementItems,
    imports,
    properties,
    auctions,
    auctionFees,
    bidSteps,
    bids,
    spots,
    policies,
    spotCategories,
    equipmentCategories,
    equipments,
    spotTypes,
    options,
    organizationOptions,
    bookings,
    pricingRules,
    pricingSeasons,
    creditCards,
    templatesMails,
    typesMails,
    templatesInvoices,
    elixioServicesModule,
  },
});
