export default {
  REPORTS: "Rapports",
  STATISCTICS_LIST: "Rapport et stats",
  PAYMENT_STATISTICS: "Rapport de paiements",
  REPORT_OCC: "Rapport d'occupation",
  OWNER: "Organisation",
  CAPACITY: "Capacité",
  CAPACITY_RESERVED: "Réservées",
  OCCUPATION_STATS: "Statistiques d’occupation",
  RESERVED: "Nuits réservées",
  INCOME: "Revenu global",
  NIGHTS: "Nuitées",
  TOTAL_SPOTS: "Total emplacements",
  TOTAL_OCC_SPOTS: "Emplacements réservées",
  AVG_LENGHT_OCC: "Durée moyenne d'occupation",
  OVERALL_OCC_RATE: "Taux d'occup. global",
  OCCUPATIONS_LIST: "Liste des occupations",
  PAYMENTS_LIST: "Liste des paiements",
  STATS: "Statistiques",
  OCCUPAIONS_BY_LOCATIONS: "Occupations par emplacements",
  OCCUPATIONS_RATES: "Taux occupations",
  OCCUPATIONS_BY_PERIOD: "Occupations par période",
  FULL_DAY: "Journée entière",
  HALF_DAY: "Demi journée",
  ABSENT: "Absent",
  CLOSED: "Fermé",
  DAYS: "Jours",
  OVERALL_OCC_RATE: "Taux d'occupation global",
  OCCUPATIONS_LIST: "Liste des occupations",
  ADR: "Prix Moyen",
  RESERVATION_RATE: "Taux d'occupation (%)",
  RESERVED_SPOTS: "Chambres Réservées",
  RESERVED_PERC: "Réservées %",
  REVENUES: "Revenus",
  TOTAL_REVENUE: "Revenus de Location",
  MONTH: "Mois",
  JANUARY: "Janvier",
  FEBRUARY: "Février",
  MARCH: "Mars",
  APRIL: "Avril",
  MAY: "Mai",
  JUNE: "Juin",
  JULY: "Juillet",
  AUGUST: "Août",
  SEPTEMBER: "Septembre",
  OCTOBER: "Octobre",
  NOVEMBER: "Novembre",
  DECEMBER: "Décembre",
  AVAILABLE_SPOTS: "Emplacements Disponibles",
  USER: "Utilisateur",
  FOLIO: "Folio/Facture",
  BOOKING: "Réservation",
  PAYMENTS: "Paiements",
  PAYMENT_MODE: "Mode de paiement",
  DATE_HOUR: "Date et Heure",
  TOTAL_REC_PAYMENTS: "Total des paiements reçus",
  ARRIVING_DATE: "Date d'arrivée",
  AVG_AMOUNT_PER_TRANS: "Montant moyen par transaction",
  TOTAL_REMAINING_PAYMENTS: "Total des paiements en attente",
  PERC_GLOBAL_PAYMENT: "Pourcentage global de paiement"
};
