import { Pie, mixins } from "vue-chartjs";

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: ['data', 'options'],
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
