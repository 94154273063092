import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

let queryIncluded = `recipient,
bookings,
items,
items.salesInvoiceable,
items.salesInvoiceable.spot,
itemsBookings,
itemsBookings.salesInvoiceable,
itemsBookings.salesInvoiceable.spot`;

queryIncluded = queryIncluded.replace(/\s+/g, '')

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/sales-invoices?include=${queryIncluded}`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [
    "organization",
    "recipient",
    "recipient.organization",
    "items",
    "allowedLocations",
    "salesOrder",
    "sourceWarehouse",
    "itemsBookings",
    "itemsBookings.salesInvoiceable",
    "itemsBookings.salesInvoiceable.spot",
    "itemsBookings.salesInvoiceable.spot.category",
    "itemsBookings.salesInvoiceable.spot.spotType",
    "itemsOthersFees"
  ].join(",");

  return axios
    .get(
      `${url}/sales-invoices/${id}?include=${includes}`,
      options
    )
    .then((response) => {
      let invoice = jsona.deserialize(response.data);
      delete invoice.links;
      return invoice;
    });
}

function add(invoice) {
  const payload = jsona.serialize({
    stuff: invoice,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/sales-invoices`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(invoice) {
  const payload = jsona.serialize({
    stuff: invoice,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/sales-invoices/${invoice.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/sales-invoices/${id}`, options);
}

function validate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/sales-invoices/${id}/validate?include=organization,recipient,items,allowedLocations,salesOrder`,
      options
    )
    .then((response) => {
      let invoice = jsona.deserialize(response.data);
      delete invoice.links;
      return invoice;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/sales-invoices/${id}/cancel?include=organization,recipient,items,allowedLocations,salesOrder`,
      options
    )
    .then((response) => {
      let invoice = jsona.deserialize(response.data);
      delete invoice.links;
      return invoice;
    });
}

function sendMailToCustomer(invoiceId) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/sales-invoices/${invoiceId}/send-invoice-mail`, options);
}

function chargeDifference(invoiceId, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/sales-invoices/${invoiceId}/payments/charge-difference`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function refund(invoiceId) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/sales-invoices/${invoiceId}/payments/refund`,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function previewFromUpdatedBooking(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    // params: {
    //   newBooking: data.newBooking,
    //   // include: queryIncluded
    // },
    // paramsSerializer: function (params) {
    //   return qs.stringify(params, { encode: false });
    // }
  };

  let params = {
    newBooking: data.newBooking,
    // include: queryIncluded
  };

  return axios
    .post(
      `${url}/sales-invoices/${data.invoiceId}/preview-update?include=${queryIncluded}`,
      params,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
     // return response.data;
    });
}

function processToUpdate(data) {
  const options = {
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const id = data.invoiceId;
  delete data.invoiceId;

  if(data.card === null){
    delete data.card
  }

  return axios
    .post(
      `${url}/sales-invoices/${id}/process-to-update?include=${queryIncluded}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  validate,
  cancel,
  chargeDifference,
  refund,
  sendMailToCustomer,
  previewFromUpdatedBooking,
  processToUpdate
};
