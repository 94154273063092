<template>
    <el-select
      v-model="selectedPaymentMethods"
      multiple
      collapse-tags
      :placeholder="placeholder ?? $t('REPORTS.PAYMENT_MODE')"
      @change="paymentCardChange"
    >
      <el-option
        v-for="method in paymentMethods"
        :key="method"
        :label="method"
        :value="method"
      />
    </el-select>
  </template>
  
  <script>
  import { Select, Option } from 'element-ui';
  
  export default {
    name: 'payment-card-selector',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        paymentMethods: ['UNKNOWN', 'CLOVER', 'CASH', 'DEBIT'],
        selectedPaymentMethods: [],
      };
    },
    methods: {
      paymentCardChange(card) {
        //console.log('Selected payment methods:', value);
        this.$emit("paymentCardChange", card);
      },
    },
  };
  </script>
  
