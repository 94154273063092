<template>
  <el-select
    v-model="selectedMonths"
    @change="monthChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="placeholder ?? $t('COMMON.MONTH')"
    :multiple="true"
    :filterable="true"
    :disabled="disabled"
  >
    <el-option
      v-for="month in months"
      :key="month.value"
      :value="month.value"
      :label="month.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import moment from "moment";

export default {
name: "spot-month-selector",

components: {
  [Select.name]: Select,
  [Option.name]: Option,
},

props: {
  disabled: {
    type: Boolean,
    default: false,
    description: "Disable the input",
  },
  placeholder: {
    type: String,
    default: null,
    description: "Placeholder",
  },
},

data() {
  return {
    selectedMonths: [],
    months: this.generateMonths(),
  };
},

methods: {
  generateMonths() {
    let months = [];
    let start = moment("2024-09-01"); // Start from September 2024
    let end = moment().add(1, "years").endOf("year"); // Go up to December 2 years later

    while (start.isSameOrBefore(end, "month")) {
      months.push({
        value: start.format("YYYY-MM"),
        label: start.format("MMMM YYYY"),
      });
      start.add(1, "month");
    }

    return months;
  },

  monthChanged(selected) {
    this.$emit("monthChanged", selected);
  },
},
};
</script>
