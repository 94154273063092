export default {
  ORGANIZATIONS_LIST: "Liste des campings",
  ADD_ORGANIZATION: "Ajouter un camping",
  ORGANIZATION_ADDED: "Camping ajouté",
  OWNER: "Propriétaire",
  EDIT_ORGANIZATION: "Modifier un camping",
  ORGANIZATION_UPDATED: "Camping mis à jour",
  SELECT_A_PACKAGE: "Choisir un forfait",
  ADD_SUBSCRIPTION: "Ajouter un abonnement",
  SUBSCRIPTION_INVOICE_ADDED: "Facture abonnement ajoutée",
  AUTHORIZATIONS: "Autorisations",
  DELETE_THIS_ORGANIZATION: "Supprimer ce camping ?",
  ORGANIZATION_DELETED: "Camping supprimé",
  VIEW_ORGANIZATION: "Détails du camping",
  VIEW_ELIXIO_SYNC: "Synchronisation",
  ADD_ELIXIO_SYNC_SERVICE : "Ajouter un service",
  ADD_ELIXIO_SYNC_CONFIGURE_THIS_SERVICE : "Configurer",
  ELIXIO_SYNC_SUBMIT : "Soumettre",
  ELIXIO_SYNC_TEST_CONFIG : "Configurations Test",
  ELIXIO_SYNC_PROD_CONFIG : "Configurations Prod",
  ELIXIO_SYNC_CONFIRM_THIS_SUBSCRIPTION : "Confirmer cette action?",
  ELIXIO_ADDITIONNAL_CONFIG_TITLE : "Configuration additionnelle",
  NO_DATA: "Vous n’avez ajouté aucun camping pour le moment, cliquer sur le bouton pour ajouter.",
};
