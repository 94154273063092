import axios from "axios";

const url = `${process.env.VUE_APP_API_BASE_URL}/organizations`;

async function getAllServices(organisationId) {
  const options = {
  
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return await axios.get(`${url}/${organisationId}/sync/services`, options)
               ;
}


async function subscribe(organisationId,data) {
 
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

 // console.log("service.DATA => " , data)

  return await axios
            .post(`${url}/${organisationId}/sync/subscribe`, data, options)
            
}

async function updateConfigs(organisationId,data) {
 
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return await axios
            .post(`${url}/${organisationId}/sync/update-configs`, data, options)
            
}


export default {
  getAllServices,
  subscribe,
  updateConfigs,

};
