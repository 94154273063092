<template>
  <div>
    <div class="page-wrapper-table stats">
      <div class="stats-wrapper">
        <div class="stats-wrapper-title">
          <h4>{{ $t("REPORTS.OCCUPATION_STATS") }}</h4>
        </div>
        <div class="stats-wrapper-filters">
          <div class="stats-wrapper-filters-left">
            <!--<div class="item">
              <el-select v-model="selectedYear" placeholder="Année">
                <el-option
                  v-for="item in years"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <el-select v-model="selectedMonth" placeholder="Mois">
                <el-option
                  v-for="item in months"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <base-input class="search-dates">
                <el-date-picker
                  v-model="periodDateRange"
                  type="daterange"
                  start-placeholder="Date de début"
                  end-placeholder="Date de fin"
                  format="dd MMM yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </base-input>
            </div>-->
          </div>
          <!-- <div class="stats-wrapper-filters-right">
            <el-radio-group v-model="downloadRadio">
              <el-radio :label="3">
                <img src="/img/kw-excel.svg" alt="icon" />
                <span>Excel</span>
              </el-radio>
              <el-radio :label="6">
                <img src="/img/kw-csv.svg" alt="icon" />
                <span>CSV</span>
              </el-radio>
              <el-radio :label="9">
                <img src="/img/kw-pdf.svg" alt="icon" />
                <span>PDF</span>
              </el-radio>
            </el-radio-group>

            <Button @click="printFunctionCall">
              <img src="/img/kw-printer.svg" alt="icon" />
              <span>{{ $t("COMMON.EXPORT") }}</span>
            </Button>
          </div> -->
        </div>
      </div>

      <WidgetsStatistic :statistics="this.statistics"> </WidgetsStatistic>

      <!--<div class="stats-graphics">
        <div class="stats-graphics-inner">
          <div class="stats-graphics-inner-item pie">
            <div class="stats-graphics-inner-item-title">
              <p>{{ $t("REPORTS.STATS") }}</p>
              <div class="legend">
                <h4>{{ $t("REPORTS.OCCUPAIONS_BY_LOCATIONS") }}</h4>
              </div>
            </div>
            <div class="stats-graphics-inner-item-content">
              <div class="chart pie">
                <CustomPieChart
                  :data="chartPieData"
                  :options="chartPieOptions"
                />
              </div>
              <CustomPieChartLegendVue :legendValues="legendValues" />
            </div>
          </div>
          <div class="stats-graphics-inner-item waffle">
            <div class="stats-graphics-inner-item-title">
              <p>{{ $t("REPORTS.STATS") }}</p>
              <div class="legend">
                <h4>{{ $t("REPORTS.OCCUPATIONS_RATES") }}</h4>
                <ul>
                  <li class="full-day">
                    <span class="round"></span>
                    <span>{{ $t("REPORTS.FULL_DAY") }}</span>
                  </li>
                  <li class="half-day">
                    <span class="round"></span>
                    <span>{{ $t("REPORTS.HALF_DAY") }}</span>
                  </li>
                  <li class="absent">
                    <span class="round"></span>
                    <span>{{ $t("REPORTS.ABSENT") }}</span>
                  </li>
                  <li class="closed">
                    <span class="round"></span>
                    <span>{{ $t("REPORTS.CLOSED") }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="stats-graphics-inner-item-content"></div>
          </div>
          <div class="stats-graphics-inner-item line">
            <div class="stats-graphics-inner-item-title">
              <p>{{ $t("REPORTS.STATS") }}</p>
              <div class="legend">
                <h4>{{ $t("REPORTS.OCCUPATIONS_BY_PERIOD") }}</h4>
                <ul>
                  <li class="days">
                    <span class="round"></span>
                    <span>{{ $t("REPORTS.DAYS") }}</span>
                  </li>
                  <li class="month">
                    <span class="round"></span>
                    <span>{{ $t("COMMON.MONTH") }}</span>
                  </li>
                  <li class="seasons">
                    <span class="round"></span>
                    <span>{{ $t("COMMON.SEASONS") }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="stats-graphics-inner-item-content">
              <CustomLineChart
                :data="chartLineData"
                :options="chartLineOptions"
              />
            </div>
          </div>
        </div>
      </div>-->

      <h4 class="title">{{ $t("REPORTS.OCCUPATIONS_LIST") }}</h4>

      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <!-- <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
            @searchChanged="(spotSearch) => (selectedSearch = spotSearch)"
          /> -->

          <!-- <base-input class="search-dates">
            <el-date-picker
              v-model="selectedDateRange"
              type="daterange"
              start-placeholder="Date de début"
              end-placeholder="Date de fin"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </base-input> -->
          
          <spot-month-selector
            :placeholder="$t('COMMON.MONTH')"
            @monthChanged="(months) => (selectedMonths = months)"
          />

          <spot-category-selector
            :showAll="false"
            :multiple="true"
            @spotCategoryChanged="
              (spotCategories) => (selectedCategories = spotCategories)
            "
          />
          <spot-type-selector
            :placeholder="$t(`SPOTS.SPOT_TYPE`)"
            :showAll="false"
            :multiple="true"
            @spotTypeChanged="(spotTypes) => (selectedSpotTypes = spotTypes)"
          />

        </div>

        <!-- <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>

      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <!--show-summary-->
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="tableDataWithTotal"
            :default-sort="{ prop: 'code', order: 'ascending' }"
            :loading="loading"
            :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column :label="$t('REPORTS.MONTH')" prop="month" min-width="140" sortable>
              <template v-slot="{ row }">
                <span v-if="row.isTotal" class="font-bold" style="font-weight: 600;">{{ $t("COMMON.TOTAL") }}</span>
                <span v-else>{{ translateMonth(row.month) }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('REPORTS.AVAILABLE_SPOTS')" prop="total_spots" min-width="100" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ row.available_spots }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column :label="$t('REPORTS.RESERVED_SPOTS')" prop="spots_reserved" min-width="160" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ row.spots_reserved }}</span>
              </template>
            </el-table-column> -->

            <el-table-column :label="$t('REPORTS.RESERVATION_RATE')" prop="reserved_percentage" min-width="160" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ row.reserved_percentage }} %</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('REPORTS.REVENUES')" prop="revenue" min-width="120" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ Math.round(row.revenue * 100) / 100 }} $</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('REPORTS.ADR')" prop="adr" min-width="120" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ row.adr }} $</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('REPORTS.NIGHTS')" prop="nights_booked" min-width="120" sortable>
              <template v-slot="{ row }">
                <span :style="{ fontWeight: row.isTotal ? '600' : '400' }">{{ row.nights_booked }}</span>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>

      <!-- <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DatePicker,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
  RadioGroup,
  Radio,
} from "element-ui";

// import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SpotTypeSelector from "@/components/SpotTypeSelector";
import SpotMonthSelector from "@/components/SpotMonthSelector";
import SpotCategorySelector from "@/components/SpotCategorySelector";
import EquipmentSelector from "@/components/EquipmentSelector";
import WidgetsStatistic from "../components/WidgetsStatistic.vue";
import moment from "moment";
import "jspdf-autotable";
import CustomPieChart from "../components/CustomPieChart/CustomPieChart.js";
import CustomLineChart from "../components/CustomLineChart/CustomLineChart";
import CustomPieChartLegendVue from "../components/CustomPieChart/CustomPieChartLegend.vue";

export default {
  name: "statistics-list-table",

  components: {
    BasePagination,
    // flatPicker,
    OrganizationSelector,
    LocationsSelector,
    EquipmentSelector,
    SpotCategorySelector,
    SpotTypeSelector,
    SpotMonthSelector,
    CustomPieChart,
    CustomLineChart,
    CustomPieChartLegendVue,
    WidgetsStatistic,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  created() {
    this.optionsTabs = [
      { label: "Tous", value: "all" },
      { label: "Arrivées", value: "arrived" },
      { label: "Départs", value: "departs" },
      { label: "Résidents", value: "residents" },
    ];
    this.selectedOptionTabId = this.listName;
  },

  data() {
    return {
      list: [],
      completeList: [],
      statistics: {},
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100, 500],
      },
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      selectedCategories: null,
      selectedSpotTypes: null,
      selectedMonths: null,
      selectedDateRange: [
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
      ],
      periodDateRange: [
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
      ],
      selectedYear: null,
      selectedMonth: null,
      downloadRadio: 3,
      years: [
        { id: 1, label: "2024", value: "2024" },
        { id: 2, label: "2025", value: "2025" },
      ],
      months: [
        { id: 1, label: "Janvier", value: "Janvier" },
        { id: 2, label: "Février", value: "Février" },
        { id: 3, label: "Mars", value: "Mars" },
        { id: 4, label: "Avril", value: "Avril" },
        { id: 5, label: "Mai", value: "Mai" },
        { id: 6, label: "Juin", value: "Juin" },
        { id: 7, label: "Juillet", value: "Juillet" },
        { id: 8, label: "Aout", value: "Aout" },
        { id: 9, label: "Septembre", value: "Septembre" },
        { id: 10, label: "Octobre", value: "Octobre" },
        { id: 11, label: "Novembre", value: "Novembre" },
        { id: 12, label: "Décembre", value: "Décembre" },
      ],
      legendValues: [
        {
          label: "Terrain",
          color: "#94BFBC",
          percentage: "69.11%",
          variation: "+2.98%",
        },
        {
          label: "Prêt à camper",
          color: "#F2BC5C",
          percentage: "19.08%",
          variation: "-3.25%",
        },
        {
          label: "Roulotte",
          color: "#AEC082",
          percentage: "19.08%",
          variation: "+2.98%",
        },
        {
          label: "Propriété",
          color: "#E5EAFC",
          percentage: "39.01%",
          variation: "-3.25%",
        },
      ],
      chartPieData: {
        labels: ["Terrain", "Prêt à camper", "Roulotte", "Propriété"],
        datasets: [
          {
            label: "Stats",
            backgroundColor: ["#94BFBC", "#F2BC5C", "#AEC082", "#E5EAFC"],
            data: [60, 20, 10, 10],
            borderWidth: 0,
          },
        ],
      },
      chartPieOptions: {
        responsive: true,
        legend: false,
      },
      chartLineData: {
        labels: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun"],
        datasets: [
          {
            data: [100000, 53000, 58000, 120000, 105000, 52000],
            label: "Jours",
            backgroundColor: "#AEC082",
            borderColor: "#AEC082",
            pointBorderColor: "#AEC082",
            pointBorderWidth: 0,
            pointHoverBackgroundColor: "#EF8152",
            pointHoverBorderWidth: 10,
          },
          {
            data: [51000, 58000, 50010, 51000, 52000, 50000],
            label: "Mois",
            backgroundColor: "#EF8152",
            pointBorderColor: "transparent",
            pointBorderWidth: 0,
            pointHoverBackgroundColor: "#EF8152",
            pointHoverBorderWidth: 10,
            borderColor: "#EF8152",
          },
          {
            data: [50000, 51200, 51000, 120000, 150000, 11000],
            label: "Saisons",
            backgroundColor: "#A5039F",
            pointBorderColor: "#A5039F",
            pointBorderWidth: 0,
            borderColor: "#A5039F",
          },
        ],
      },
      chartLineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              scaleLabel: {
                fontFamily: "Greycliff CF Bold",
              },
              ticks: {
                maxTicksLimit: 0,
                stepSize: 1,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                max: 500000,
                min: 0,
                stepSize: 100000,
                callback: function (context) {
                  if (context === 0) {
                    return context;
                  } else {
                    return context / 1000 + "k";
                  }
                },
                padding: 12,
              },
              gridLines: {
                drawBorder: false,
              },
            },
          ],
        },
        elements: {
          line: {
            fill: false,
            borderWidth: 2.6,
          },
        },
      },
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    tableDataWithTotal() {
      if (!this.list.length) return [];

      const totalRow = {
        isTotal: true, // Used to format the row differently
        month: this.$t("COMMON.TOTAL"),
        available_spots: this.statistics.total_spots,
        //total_bookings: this.list.reduce((sum, item) => sum + (item.total_bookings || 0), 0),
        spots_reserved: this.list.reduce((sum, item) => sum + (item.spots_reserved || 0), 0),
        reserved_percentage: this.statistics.reserved_percentage, // Custom function
        revenue: this.list.reduce((sum, item) => sum + (item.revenue || 0), 0),
        adr: this.calculateADR(), // Custom function
        nights_booked: this.list.reduce((sum, item) => sum + (item.nights_booked || 0), 0),
      };

      return [...this.list, totalRow]; // Append the total row
    }
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedDateRange: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCategories: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSpotTypes: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedMonths: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
  },

  mounted() {},

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    getStrMonth (dateStr) {
      const date = new Date(dateStr + "-01");
      return date.toLocaleString("en-US", { month: "long", year: "numeric" });
    },

    translateMonth(label) {
      let month = label.slice(0, -5).trim().toUpperCase();
      let year = label.slice(-4);
      return `${this.$t(`REPORTS.${month}`)} ${year}`;
    },

    calculateADR() {
      const totalRevenue = this.list.reduce((sum, item) => sum + (item.revenue || 0), 0);
      const totalNights = this.list.reduce((sum, item) => sum + (item.nights_booked || 0), 0);
      return totalNights ? (totalRevenue / totalNights).toFixed(2) : 0;
    },
    calculateAveragePercentage() {
      return this.statistics.reserved_percentage;
      // const totalPercentage = this.list.reduce((sum, item) => sum + (item.reserved_percentage || 0), 0);
      // return (totalPercentage / this.list.length).toFixed(2);
    },

    async getList() {
      try {
        this.loading = true;
        let params = {
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,category",
        };
        // if (this.selectedDateRange) {
        //   params = {
        //     ...params,
        //     filter: {
        //       ...params.filter,
        //       dates_between: this.selectedDateRange.map((date) =>
        //         moment(date).toISOString()
        //       ),
        //     },
        //   };
        // }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedCategories) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_categories: this.selectedCategories,
            },
          };
        }
        if (this.selectedSpotTypes) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_types: this.selectedSpotTypes,
            },
          };
        }
        if (this.selectedMonths?.length) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_months: this.selectedMonths,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        await this.$store.dispatch("reportStatistics/list", params);

        this.list = this.$store.getters["reportStatistics/list"];
        this.total = this.$store.getters["reportStatistics/listTotal"];
        this.statistics = this.$store.getters["reportStatistics/statistics"];
        console.log(this.statistics);
        this.completeList =
          this.$store.getters["reportStatistics/completeList"];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("error ", error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      this.sort = order === "ascending" ? prop : `-${prop}`;
      this.getList();
    },

    formatNumber(value, decimals = 2, symbol = "", locale = "en-US") {
      // Vérifie si la valeur est un nombre valide
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return "0";
      }

      // Formatage avec séparateur de milliers et décimales
      const formattedValue = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }).format(numberValue);
      // Retourne la valeur formatée avec le symbole (si fourni)
      return symbol ? `${formattedValue} ${symbol}` : formattedValue;
    },

    

    // exportToCSV() {
    //   const organizationName = this.list[0]?.organization?.name || "unset";
    //   const dateRange = this.selectedDateRange
    //     ? `${this.selectedDateRange[0]} - ${this.selectedDateRange[1]}`
    //     : "Période non spécifiée";
    //   const fileName = `${organizationName}-Occupation_Revenu_${dateRange}.csv`;

    //   const data = this.completeList.map((item) => ({
    //     [this.$t("SPOTS.SPOT_NUMBER")]: item.code,
    //     [this.$t("SPOTS.SPOT_NAME")]: item.name,
    //     [this.$t("COMMON.CATEGORY")]: item.category?.name || "",
    //     [this.$t("COMMON.TYPE")]: item.spot_type.name,
    //     [this.$t("COMMON.NIGHTS_RESERVED")]: item.nights_reserved,
    //     [this.$t("COMMON.RESERVED")]: this.formatNumber(
    //       item.reservation_percentage,
    //       2,
    //       ""
    //     ),
    //     [this.$t("COMMON.REVENUE")]: this.formatNumber(
    //       item.total_revenue,
    //       2,
    //       ""
    //     ),
    //     [this.$t("COMMON.PRICE_PER_NIGHT_AVERAGE")]: this.formatNumber(
    //       item.average_price_per_night,
    //       2,
    //       ""
    //     ),
    //     [this.$t("COMMON.REV_PER_AVL_SPOT")]: this.formatNumber(
    //       item.revenue_per_available_room,
    //       2,
    //       ""
    //     ),
    //     [this.$t("COMMON.NIGHTS_TOTAL")]: item.nights_by_periode,
    //   }));

    //   // data.push({
    //   //   [this.$t("SPOTS.SPOT_NUMBER")]: "",
    //   //   [this.$t("SPOTS.SPOT_NAME")]: "TOTAL",
    //   //   [this.$t("COMMON.CATEGORY")]: "",
    //   //   [this.$t("COMMON.TYPE")]: "",
    //   //   [this.$t("COMMON.NIGHTS_RESERVED")]: this.statistics.nights_reserved,
    //   //   [this.$t("COMMON.RESERVED")]: this.formatNumber(this.statistics.reservation_percentage, 2, "%"),
    //   //   [this.$t("COMMON.REVENUE")]: this.formatNumber(this.statistics.total_revenue, 2, "$"),
    //   //   [this.$t("COMMON.PRICE_PER_NIGHT_AVERAGE")]: this.formatNumber(this.statistics.average_price_per_night, 2, "$"),
    //   //   [this.$t("COMMON.NIGHTS_TOTAL")]: this.statistics.nights_by_periode,
    //   // });

    //   const csv = Papa.unparse(data, { delimiter: "," });

    //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    //   saveAs(blob, fileName);
    // },

    // exportToExcel() {
    //   const organizationName = this.list[0]?.organization?.name || "unset";
    //   const dateRange = this.selectedDateRange
    //     ? `${this.selectedDateRange[0]} - ${this.selectedDateRange[1]}`
    //     : "Période non spécifiée";
    //   const fileName = `${organizationName}-Occupation_Revenu_${dateRange}.xlsx`;

    //   const data = this.completeList.map((item) => [
    //     item.code,
    //     item.name,
    //     item.category?.name || "",
    //     item.spot_type.name,
    //     item.nights_reserved,
    //     this.formatNumber(item.reservation_percentage, 2, ""),
    //     this.formatNumber(item.total_revenue, 2, ""),
    //     this.formatNumber(item.average_price_per_night, 2, ""),
    //     this.formatNumber(item.revenue_per_available_room, 2, ""),
    //     item.nights_by_periode,
    //   ]);

    //   // 3️⃣ Ajout d'une ligne des totaux
    //   // data.push([
    //   //   "",
    //   //   "TOTAL",
    //   //   "",
    //   //   "",
    //   //   this.statistics.nights_reserved,
    //   //   this.formatNumber(this.statistics.reservation_percentage, 2, "%"),
    //   //   this.formatNumber(this.statistics.total_revenue, 2, "$"),
    //   //   this.formatNumber(this.statistics.average_price_per_night, 2, "$"),
    //   //   this.statistics.nights_by_periode,
    //   // ]);

    //   // 4️⃣ Création du fichier Excel
    //   const worksheet = XLSX.utils.aoa_to_sheet([
    //     [
    //       this.$t("SPOTS.SPOT_NUMBER"),
    //       this.$t("SPOTS.SPOT_NAME"),
    //       this.$t("COMMON.CATEGORY"),
    //       this.$t("COMMON.TYPE"),
    //       this.$t("COMMON.NIGHTS_RESERVED"),
    //       this.$t("COMMON.RESERVED"),
    //       this.$t("COMMON.REVENUE"),
    //       this.$t("COMMON.PRICE_PER_NIGHT_AVERAGE"),
    //       this.$t("COMMON.REV_PER_AVL_SPOT"),
    //       this.$t("COMMON.NIGHTS_TOTAL"),
    //     ],
    //     ...data,
    //   ]);

    //   // 5️⃣ Appliquer un style pour l'entête (couleur)
    //   const headerStyle = {
    //     fill: { fgColor: { rgb: "576654" } }, // Couleur verte foncée
    //     font: { bold: true, color: { rgb: "FFFFFF" } }, // Texte en blanc
    //     alignment: { horizontal: "center" },
    //   };

    //   // Appliquer le style aux cellules d'entête
    //   const range = XLSX.utils.decode_range(worksheet["!ref"]);
    //   for (let C = range.s.c; C <= range.e.c; C++) {
    //     const cellAddress = XLSX.utils.encode_col(C) + "1"; // Ligne 1 (header)
    //     if (!worksheet[cellAddress]) continue;
    //     worksheet[cellAddress].s = headerStyle;
    //   }

    //   // 6️⃣ Création du workbook
    //   const workbook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(workbook, worksheet, "Rapport");

    //   // 7️⃣ Générer le fichier et télécharger
    //   const excelBuffer = XLSX.write(workbook, {
    //     bookType: "xlsx",
    //     type: "array",
    //   });
    //   const excelData = new Blob([excelBuffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   });
    //   saveAs(excelData, fileName);
    // },

    // exportToPDF() {
    //   const doc = new jsPDF("landscape", "mm", "a4");

    //   const organizationName = this.list[0]?.organization?.name
    //     ? this.list[0]?.organization?.name
    //     : "unset";
    //   const dateRange = this.selectedDateRange
    //     ? `${this.selectedDateRange[0]} - ${this.selectedDateRange[1]}`
    //     : "Période non spécifiée";

    //   const reportNametName = `${organizationName}-Occupation et Revenu ${dateRange} `;

    //   doc.setFontSize(11);
    //   doc.text(reportNametName, 10, 10);
    //   doc.setFontSize(12);

    //   const tableData = this.completeList.map((item) => [
    //     item.code,
    //     item.name,
    //     item.category?.name || "",
    //     item.spot_type.name,
    //     item.nights_reserved,
    //     this.formatNumber(item.reservation_percentage, 2, "%"),
    //     this.formatNumber(item.total_revenue, 2, "$"),
    //     this.formatNumber(item.average_price_per_night, 2, "$"),
    //     this.formatNumber(item.revenue_per_available_room, 2, "$"),
    //     item.nights_by_periode,
    //   ]);

    //   doc.autoTable({
    //     head: [
    //       [
    //         this.$t("SPOTS.SPOT_NUMBER"),
    //         this.$t("SPOTS.SPOT_NAME"),
    //         this.$t("COMMON.CATEGORY"),
    //         this.$t("COMMON.TYPE"),
    //         this.$t("COMMON.NIGHTS_RESERVED"),
    //         this.$t("COMMON.RESERVED"),
    //         this.$t("COMMON.REVENUE"),
    //         this.$t("COMMON.PRICE_PER_NIGHT_AVERAGE"),
    //         this.$t("COMMON.REV_PER_AVL_SPOT"),
    //         this.$t("COMMON.NIGHTS_TOTAL"),
    //       ],
    //     ],
    //     body: tableData,
    //     startY: 20,
    //     theme: "grid",
    //     headStyles: {
    //       fillColor: [87, 102, 84], // Couleur personnalisée #576654
    //       textColor: [255, 255, 255], // Texte en blanc
    //       fontStyle: "bold",
    //       halign: "center",
    //       valign: "middle",
    //     },
    //     styles: {
    //       fontSize: 10,
    //     },
    //     showFoot: "lastPage",

    //     foot: [
    //       [
    //         "",
    //         this.$t("REPORTS.CAPACITY") + ": " + this.statistics.spots,
    //         this.$t("REPORTS.CAPACITY_RESERVED") +
    //           ": " +
    //           this.statistics.spots_reserved,
    //         this.$t("REPORTS.CAPACITY_RESERVED") +
    //           ": " +
    //           this.statistics.average_spots_reserved +
    //           " %",
    //         this.statistics.nights_reserved,
    //         this.formatNumber(this.statistics.reservation_percentage, 2, "%"),
    //         this.formatNumber(this.statistics.total_revenue, 2, "$"),
    //         this.formatNumber(this.statistics.average_price_per_night, 2, "$"),
    //         this.formatNumber(
    //           this.statistics.revenue_per_available_room,
    //           2,
    //           "$"
    //         ),
    //         this.statistics.nights_by_periode,
    //       ],
    //     ],
    //     footStyles: {
    //       fillColor: [50, 50, 50], // Gris foncé
    //       textColor: [255, 255, 255], // Blanc
    //       fontStyle: "bold",
    //     },
    //     didDrawPage: function (data) {
    //       // Ajouter la pagination en bas de chaque page
    //       const pageCount = doc.getNumberOfPages();
    //       doc.setFontSize(10);
    //       doc.text(
    //         `Page ${doc.internal.getNumberOfPages()} / ${pageCount}`,
    //         270,
    //         200
    //       );
    //     },
    //   });
    //   const now = new Date();
    //   const formattedDate =
    //     now.toLocaleDateString() + " " + now.toLocaleTimeString();
    //   doc.setFontSize(10);
    //   doc.text(`File created: ${formattedDate}`, 10, 200);
    //   doc.save(`${reportNametName}.pdf`);
    // },

    printFunctionCall() {
      console.log(this.years);
      if (this.downloadRadio === 3) {
        this.exportToExcel();
      } else if (this.downloadRadio === 6) {
        this.exportToCSV();
      } else {
        this.exportToPDF();
      }
    },
  },
};
</script>
