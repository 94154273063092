export default {
  TEMPLATES_INVOICES_LIST: "Liste des modèles factures",
  ADD_TEMPLATE_INVOICE: "Ajouter un modèle facture",
  TEMPLATE_INVOICE_ADDED: "Modèle facture ajouté",
  EDIT_TEMPLATE_INVOICE: "Modifier un modèle facture",
  TEMPLATE_INVOICE_UPDATED: "Modèle facture mise à jour",
  DELETE_THIS_TEMPLATE_INVOICE: "Supprimer ce modèle facture ?",
  TEMPLATE_INVOICE_DELETED: "Modèle facture supprimée",
  TEMPLATE_DETAIL: "Détails du modèle",
  SUBJECT: "Objet",
  TITLE: "Libellé",
  FROM: "De",
  TO: "À",
  CC: "Cc",
  BCC: "Cci",
  TYPE: "Type",
  CONTENT: "Contenu",
  VIEW_TEMPLATE_INVOICE: "Modèle facture",
  COMMUNICATION: "Communication",
  LOGO: "Logo",
  BACKGROUND_COLOR: "Couleur du fond",
  HEADER_COLOR: "Couleur de l'entéte",
  SECTION_COLOR: "Couleur de chaque section",
  PERSONNALISATION: "Personnalisation",
  CONTACT_DETAILS: "Coordonnées du camping",
  OTHER_INFOS: "Autres informations",
  IS_DEFAULT_TEMPLATE: "Modèle par défault",
  ADDRESS: "Adresse dans l'entéte",
  DOWNLOAD: "Télécharger",
  PRINT: "Imprimer",
  ADD_LOGO: "Ajouter un logo",
  IMPORT_LOGO: "Importer un logo",
  IMPORT_FILE: "Importer un fichier",
  SELECT_INVOICE_COLOR: "Sélectionner couleur facture",
  HEADER_INFORMATION: "Informations entête",
  FOOTER_INFORMATION: "Informations pied",
  ADDRESS_TO: "Adressé à",
  CONFIRMATION_NUMBER: "N° de confirmation",
  DONE_ON: "Fait le",
  STAY_DELAY: "Séjour du",
  TO: "au",
  QUANTITY: "Qté",
  SUB_TOTAL: "Sous total",
  AMOUNT_ALREADY_PAID: "Montant déjà payé",
  SOLD_TO_PAY: "Solde à payer",
  CREDIT_CARD: "Carte bancaire",
  THANKS: "MERCI POUR VOTRE CONFIANCE",
  PAYMENT_TO: "Paiement à l'ordre de",
  PAYMENT_CONDITION: "Condition de paiement",
  PAYMENT_WITHHIN: "Paiement sous",
  DAYS: "jours",
};
