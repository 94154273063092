import service from "@/store/services/elixio-services.service.js";
import { pick } from "lodash";

export const state = () => ({
  services: [],
});

export const mutations = {
  SET_SERVICES: (state, services) => {
    state.services = services;
  },
};

export const actions = {

 async subscribe({ commit, dispatch }, data) {

  const {organizationId , payload} = data;
    return await service.subscribe(organizationId, payload);
  },
  
  async updateConfigs({ commit, dispatch }, data) {

  const {organizationId , payload} = data;
    return await service.updateConfigs(organizationId, payload);
  },

 async update({ commit, dispatch }, params) {
   // return await service.update(params, this.$axios).then((spot) => {
              // commit("SET_RESOURCE", spot);
    //          });
  },

 

 async getAllServices({ commit, dispatch }, data) {
    return await service.getAllServices(data, this.$axios);
  },
};

const getters = {
  services: (state) => state.services,
};

const elixioServicesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default elixioServicesModule;
