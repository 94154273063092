<template>
  <div class="lang">
    <base-dropdown
      title-classes="btn btn-sm btn-neutral"
      menu-on-right
      id="lang-switcher"
      :has-toggle="false"
      menuClasses="lang-switcher"
    >
      <template slot="title">
        <span v-if="globe === true" class="globe">
          <i class="fal fa-globe"></i>
        </span>
        <span v-if="flag === true" class="flag">
          <img :src="`/img/canada-flag.svg`" />
        </span>
        <span v-if="slug === true" class="slug">{{ $i18n.locale }}</span>
        <span v-if="slug === false" class="local-lang">{{
          $i18n.locale === "fr"
            ? `${this.$t("COMMON.LOCALE_FR")}`
            : `${this.$t("COMMON.LOCALE_EN")}`
        }}</span>
        <span class="icon">
          <i class="fal fa-chevron-down"></i>
        </span>
      </template>
      <li v-for="lang in langs" :key="lang.id">
        <a
          class="dropdown-item"
          v-bind:class="{ active: lang.slug === $i18n.locale }"
          @click="selectLang(lang.slug)"
        >
          <img :src="`/img/canada-flag.svg`" />
          {{ lang.text }}
          <span class="check"><i class="fal fa-check"></i></span>
        </a>
      </li>
    </base-dropdown>
  </div>
</template>

<script>
import { loadLanguageAsync } from "../plugins/i18n";
import messages from "../i18n/fr/index";
import VueI18n from "vue-i18n";

export default {
  name: "lang-switcher",

  props: {
    slug: {
      type: Boolean,
      default: false,
    },
    globe: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    langs: [
      {
        id: 1,
        text: "Français",
        slug: "fr",
      },
      {
        id: 2,
        text: "Anglais",
        slug: "en",
      },
    ],
  }),

  methods: {
    selectLang(lang) {
      loadLanguageAsync(lang);
    },
  },
};
</script>
<style></style>
