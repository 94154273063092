<template>
  <div class="legend">
    <ul
      v-for="(legendValue, index) in legendValues"
      :key="`legend-badge-${index}`"
    >
      <li class="one">
        <span
          :style="{ 'background-color': legendValue.color }"
          class="badge"
        ></span>
        <span>{{ legendValue.label }}</span>
      </li>
      <li class="two">
        <span>{{ legendValue.percentage }}</span>
      </li>
      <li class="three">
        <span
          :class="
            legendValue.variation.substring(0, 1) === '+' ? 'green' : 'red'
          "
          >({{ legendValue.variation }})</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    legendValues: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
