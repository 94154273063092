<template>
  <ul class="sub-menu-items">
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking', query: { tab: 'list' } }"
        v-slot="{ href, navigate }"
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.NEW_BOOKING") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking', query: { tab: 'calendar' } }"
        v-slot="{ href, navigate }"
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("COMMON.CALENDAR") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CUSTOMERS)">
      <router-link
        :to="{ name: 'New Booking', query: { tab: 'map' } }"
        v-slot="{ href, navigate }"
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("COMMON.MAP") }}
          </span>
        </a>
      </router-link>
    </li>
    <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_BOOKINGS)">
      <router-link :to="{ name: 'List Bookings' }" v-slot="{ href, navigate }">
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.BOOKING_LIST") }}
          </span>
        </a>
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'Pending Bookings' }"
        v-slot="{ href, navigate }"
      >
        <a :href="href" @click="navigate">
          <span class="text">
            {{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}
          </span>
          <span v-if="cart.length" class="count"> {{ cart.length }} </span>
        </a>
      </router-link>
    </li>
  </ul>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";
import { mapGetters } from "vuex";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  created() {},

  methods: {},

  async mounted() {},

  computed: {
    ...mapGetters("profile", { me: "me" }),
    ...mapGetters("salesOrders", { cart: "cart" }),
  },

  watch: {},
};
</script>
