<template>
  <el-select
    v-model="spotCategoryModel"
    @change="spotCategoryChanged"
    autocomplete="new-password"
    collapse-tags
    :placeholder="placeholder ?? $t('COMMON.CATEGORY')"
    :filterable="filterable"
    :excludedId="excludedId"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getCategories"
    :loading="loading"
    :allow-create="allowCreate"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-if="spotCategory.id !== excludedId"
      v-for="spotCategory in spotCategories"
      :key="spotCategory.id"
      :value="spotCategory.id"
      :label="spotCategory.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "spot-category-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    excludedId: {
      type: String,
      default: null,
      description: "Spot category id to exclude",
    },
    spotCategory: {
      default: null,
      description: "Spot category id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      spotCategoryModel: this.spotCategory,
      spotCategories: {},
    };
  },

  setup() {},

  created() {
    this.getCategories(null, this.spotCategory);
  },

  methods: {
    async getCategories(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { with_ids: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        await this.$store.dispatch("spotCategories/list", params);
        const spotCategoriesArr = await this.$store.getters[
          "spotCategories/list"
        ];
        this.spotCategories = {};
        spotCategoriesArr.forEach((spotCategory) => {
          this.spotCategories[spotCategory.id] = spotCategory;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    spotCategoryChanged(spotCategory) {
      this.$emit("spotCategoryChanged", spotCategory);
    },
  },

  watch: {
    spotCategory(spotCategory) {
      if (spotCategory) {
        this.spotCategoryModel = spotCategory;
        if (spotCategory !== this.spotCategoryModel) {
          this.getCategories(null, spotCategory);
        }
      } else {
        this.spotCategoryModel = null;
      }
    },
  },
};
</script>
